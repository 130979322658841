import { CountryEnum, SalutationEnum, TaxCountryCodeEnum, TitleEnum } from '@rza-mean/api-interfaces';

export interface CompanyDataTestData {
    iban: string;
    institutionCode: string;
    name: string;
    firstName: string;
    lastName: string;
    salutation: string;
    title: string;
    street: string;
    zip: string;
    city: string;
    country: string;
    email: string;
    phone: string;
    taxId: string;
    taxCountryCode: string;
    bankInstitute: string;
    accountOwner: string;
    bic: string;
}

export const CompanyTestData: CompanyDataTestData = {
    iban: 'DE21301204000000015228',
    institutionCode: '160819895',
    name: 'test',
    firstName: 'test',
    lastName: 'test',
    salutation: SalutationEnum.HERR,
    title: TitleEnum.PROFDR,
    street: 'test',
    zip: '12345',
    city: 'test',
    country: CountryEnum.GERMANY,
    email: '',
    phone: '123456789',
    taxId: '123456789',
    taxCountryCode: TaxCountryCodeEnum.DE,
    bankInstitute: 'test',
    accountOwner: 'test',
    bic: 'test',
};
