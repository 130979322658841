import { CompanyTestData } from '../test-data/company-data';

export const environment = {
    production: false,
    isStaging: false,
    apiUrl: 'api',
    removeConfiguration: false,
    configVersion: '1.1.0',
    frontendBaseUrl: 'http://localhost:4200',
    companyData: CompanyTestData,
};
